import React from 'react';
import styles from './Page.module.css';

const Page: React.FC = ({ children }) => (
  <div className={styles.page}>
    {children}
    <div className={styles.footer}>
      &copy; 2020 Screenchat
    </div>
  </div>
);

export default Page;
