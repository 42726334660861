import React from 'react';
import styles from './Splash.module.css';

const Splash: React.FC = () => (
  <div className={styles.page}>
    <div className={styles.container}>
      <h1>Screenchat</h1>
      <p className={styles.subheader}>
        Share screenshots, with friends
      </p>

      <p className={styles.teaser}>
        Coming March 2020
      </p>
    </div>

    <div className={styles.footer}>
      &copy; 2020 Screenchat
    </div>
  </div>
);

export default Splash;
