import React from 'react';
import Page from './Page';
import styles from './Terms.module.css';

const Terms: React.FC = () => (
  <Page>
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Terms of Service</h1>
        <p>Updated: Feb 27, 2020</p>
      </div>

      <div>
        <p className={styles.title}>
          1. Terms
        </p>
        <p>
          By accessing our app, Screenchat, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Screenchat. The materials contained in Screenchat are protected by applicable copyright and trademark law.
        </p>

        <p className={styles.title}>
          2. Use License
        </p>
        <p>
          Permission is granted to temporarily download one copy of Screenchat per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
        </p>
        <p>
          modify or copy the materials;
          use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
          attempt to decompile or reverse engineer any software contained in Screenchat;
          remove any copyright or other proprietary notations from the materials; or
          transfer the materials to another person or "mirror" the materials on any other server.
        </p>
        <p>
          This license shall automatically terminate if you violate any of these restrictions and may be terminated by Screenchat at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
        </p>

        <p className={styles.title}>
          3. Disclaimer
        </p>
        <p>
          The materials within Screenchat are provided on an 'as is' basis. Screenchat makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Screenchat does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Screenchat.
        </p>

        <p className={styles.title}>
          4. Limitations
        </p>
        <p>
          In no event shall Screenchat or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Screenchat, even if Screenchat or a Screenchat authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
        </p>

        <p className={styles.title}>
          5. Accuracy of materials
        </p>
        <p>
          The materials appearing in Screenchat could include technical, typographical, or photographic errors. Screenchat does not warrant that any of the materials on Screenchat are accurate, complete or current. Screenchat may make changes to the materials contained in Screenchat at any time without notice. However Screenchat does not make any commitment to update the materials.
        </p>

        <p className={styles.title}>
          6. Links
        </p>
        <p>
          Screenchat has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Screenchat of the site. Use of any such linked website is at the user's own risk.
        </p>

        <p className={styles.title}>
          7. Modifications
        </p>
        <p>
          Screenchat may revise these terms of service for its app at any time without notice. By using Screenchat you are agreeing to be bound by the then current version of these terms of service.
        </p>

        <p className={styles.title}>
          8. Governing Law
        </p>
        <p>
          These terms and conditions are governed by and construed in accordance with the laws of CA and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </p>
      </div>
    </div>
  </Page>  
);

export default Terms;
